import { Button, Col, Form, Input, Row } from "antd";
import { useTranslation } from "react-i18next";
import CustomSelect from "../../../components/custom-select";
import ImageProfileUpload from "../../../components/image-profile-upload";
import PageHeaderEdit from "../../../components/page-header-create";
import { fix_height } from "../../news/news-edit";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { rules, VALIDATE_MESSAGES } from "../../../config/validateMessage";
import { useHistory, useParams } from "react-router-dom";
import { allCountries } from "country-telephone-data";
import { GET_ROLE_ONLY_MEMBER } from "../../../store/role";
import {
  FIXED_ACCEPT,
  FIXED_GENDER,
  FIXED_LANGUAGE,
  FIXED_STATUS,
  GET_USER_BY_ID,
  PATCH_USER,
  POST_USER,
} from "../../../store";
import { useEffect } from "react";
import dayjs from "dayjs";
import CustomDatePicker from "../../../components/custom-date-picker";
import { useQueryClient } from "react-query";
import { swalError, swalSuccess } from "../../../tools/swal";
import { USER_MANAGEMENT_RESIDENT_PATH } from "../../../router/path";
import { GET_UNIT } from "../../../store/units";
import { useNotification } from "../../../store/notification";

const UserResidentEditPage = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = useForm();
  const { t } = useTranslation();
  const roles = GET_ROLE_ONLY_MEMBER();
  const units = GET_UNIT({ pagination: false });
  const create_user = POST_USER();
  const update_user = PATCH_USER();
  const { data } = GET_USER_BY_ID(id);
  const history = useHistory();
  const client = useQueryClient();
  const { dispatch } = useNotification();

  useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data]);

  const initial = () => {
    if (!id || !data) return;
    const {
      role,
      avatar,
      dob,
      userUnits,
      acceptPdpaAt,
      acceptPolicyAt,
      acceptTermAt,
      passportId,
    } = data.data;
    form.setFieldsValue({
      ...data.data,
      role: role.id,
      avatar: avatar?.imageUrl,
      dob: dob ? dayjs(dob) : undefined,
      room: userUnits?.map((i) => i.unit.id),
      acceptPdpaAt: acceptPdpaAt ? dayjs(acceptPdpaAt) : undefined,
      acceptPolicyAt: acceptPolicyAt ? dayjs(acceptPolicyAt) : undefined,
      acceptTermAt: acceptTermAt ? dayjs(acceptTermAt) : undefined,
      passportId: passportId ? passportId : undefined,
    });
  };

  const onFinish = (values: any) => {
    if (!values.passportId && !values.identityNumber)
      return swalError(
        t,
        `${t("swal.please-complete-the-information")} ${t(
          "id-card-number"
        )} ${t("or")} ${t("passport")}`
      );
    if (id) {
      return update_user.mutate(
        { ...values, id: id },
        {
          onSuccess: () => {
            swalSuccess();
            client.invalidateQueries(["get-users-member"]);
            history.replace(USER_MANAGEMENT_RESIDENT_PATH.index);
          },
          onError: (error: any) => {
            swalError(t, error.message);
          },
        }
      );
    }
    create_user.mutate(values, {
      onSuccess: () => {
        swalSuccess();
        client.invalidateQueries(["get-users-member"]);
        history.replace(USER_MANAGEMENT_RESIDENT_PATH.index);
        dispatch!({
          type: "header",
          action: "clear",
          key: ["user-management-resident"],
        });
      },
      onError: (error: any) => {
        swalError(t, error.message);
      },
    });
  };

  return (
    <Form
      className="bg-white m-auto main-input-form pb-1"
      style={{ width: "70%" }}
      layout="vertical"
      form={form}
      onFinish={onFinish}
      validateMessages={VALIDATE_MESSAGES(t)}
      initialValues={{ isActive: false }}
    >
      <PageHeaderEdit
        label="menu.user-management-resident"
        labelTo="menu.user-management-resident"
        isLoading={create_user.isLoading || update_user.isLoading}
      />
      <Row gutter={[24, 24]} className="px-4 mb-4 pb-4">
        <Col span={9}></Col>
        <Col span={6}>
          <Form.Item noStyle name="avatar">
            <ImageProfileUpload />
          </Form.Item>
        </Col>
        <Col span={9}></Col>

        <Col span={16}>
          <Form.Item
            label={t("id-card-number")}
            name="identityNumber"
            rules={[
              // {
              //   pattern: rules.number.rule,
              //   message: t(rules.number.tooltip),
              // },
              // { max: 13 },
            ]}
          >
            <Input placeholder={t("id-card-number")} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("button")} className="lable-custom">
            <Button
              className="button-connect f-18 w-100"
              style={{ height: 45 }}
            >
              {t("connect-to-system-icon")}
            </Button>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[{ required: true }, { max: 100 }]}
            label={t("first-name")}
            name="firstName"
          >
            <Input placeholder={t("first-name")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[{ required: true }, { max: 100 }]}
            label={t("last-name")}
            name="lastName"
          >
            <Input placeholder={t("last-name")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[
              {
                pattern: rules.passport.rule,
                message: t(rules.passport.tooltip),
              },
              { max: 13 },
            ]}
            label={t("passport")}
            name="passportId"
          >
            <Input placeholder={t("passport")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[{ required: true }, { type: "email" }, { max: 255 }]}
            label={t("email")}
            name="email"
          >
            <Input placeholder={t("email")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[{ type: "email" }, { max: 255 }]}
            label={t("secondary-email")}
            name="secondEmail"
          >
            <Input placeholder={t("secondary-email")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("registration-channel")}
            name="registrationChannel"
          >
            <CustomSelect
              placeholder={t("registration-channel")}
              optionValue={{
                values: [
                  { key: "SMS", name: "sms" },
                  { key: "EMAIL", name: "email" },
                ],
                key: "key",
                name: "name",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            // rules={[{ required: true }]}
            label={t("country-code")}
            name="countryCode"
          >
            <CustomSelect
              showSearch={true}
              placeholder={t("country-code")}
              optionValue={{
                values: allCountries || [],
                key: "dialCode",
                name: "name",
                text: "dialCode",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[{ required: true }, { max: 255 }]}
            label={t("phone-number")}
            name="phoneNumber"
          >
            <Input placeholder={t("phone-number")} />
          </Form.Item>
        </Col>
        {id && (
          <Col span={12}>
            <Form.Item
              name="lineName"
              label={t("line-name")}
              className="custom-input"
            >
              <Input placeholder={t("line-name")} disabled />
            </Form.Item>
          </Col>
        )}
        <Col span={12}>
          <Form.Item label={t("language")} name="language">
            <CustomSelect
              placeholder={t("language")}
              hasTranslation={true}
              optionValue={{
                values: FIXED_LANGUAGE || [],
                key: "value",
                name: "label",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("gender")} name="gender">
            <CustomSelect
              placeholder={t("gender")}
              hasTranslation={true}
              optionValue={{
                values: FIXED_GENDER || [],
                key: "value",
                name: "label",
              }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label={t("date-of-birth")} name="dob">
            <CustomDatePicker
              placeholder={t("date-of-birth")}
              style={{ height: `${fix_height}` }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("role")} name="role" rules={[{ required: true }]}>
            <CustomSelect
              placeholder={t("role")}
              optionValue={{
                values: roles?.data || [],
                key: "id",
                name: "title",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("status")}
            name="isActive"
            rules={[{ required: true }]}
          >
            <CustomSelect
              placeholder={t("status")}
              hasTranslation={true}
              optionValue={{
                values: FIXED_STATUS || [],
                key: "value",
                name: "label",
              }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={t("terms-and-conditions")}
            name="acceptTermCondition"
          >
            <CustomSelect
              placeholder={t("terms-and-conditions")}
              hasTranslation={true}
              optionValue={{
                values: FIXED_ACCEPT,
                key: "value",
                name: "label",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("privacy-policy")} name="acceptPolicy">
            <CustomSelect
              placeholder={t("privacy-policy")}
              hasTranslation={true}
              optionValue={{
                values: FIXED_ACCEPT,
                key: "value",
                name: "label",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("personal-data-protection-act")}
            name="acceptPdpa"
          >
            <CustomSelect
              placeholder={t("personal-data-protection-act")}
              hasTranslation={true}
              optionValue={{
                values: FIXED_ACCEPT,
                key: "value",
                name: "label",
              }}
            />
          </Form.Item>
        </Col>

        {!!id && (
          <>
            <Col span={12}>
              <Form.Item
                label={t("house-number")} //unit number to use, house number to show
                name="room"
              >
                <CustomSelect
                  mode="tags"
                  placeholder={t("house-number")} //unit number to use, house number to show
                  disabled={true}
                  optionValue={{
                    values: units.data?.data || [],
                    key: "id",
                    name: "unitNumber",
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={t("terms-and-conditions-at")}
                name="acceptTermAt"
              >
                <CustomDatePicker
                  placeholder={t("terms-and-conditions-at")}
                  style={{ height: `${fix_height}` }}
                  disabled
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label={t("privacy-policy-at")} name="acceptPolicyAt">
                <CustomDatePicker
                  placeholder={t("privacy-policy-at")}
                  style={{ height: `${fix_height}` }}
                  disabled
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={t("personal-data-protection-act-at")}
                name="acceptPdpaAt"
              >
                <CustomDatePicker
                  placeholder={t("personal-data-protection-act-at")}
                  style={{ height: `${fix_height}` }}
                  disabled
                />
              </Form.Item>
            </Col>
          </>
        )}

        <Col span={24}>
          <Form.Item label={t("address")} name="address" rules={[{ max: 255 }]}>
            <Input.TextArea rows={4} placeholder={t("address")} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            shouldUpdate={(prev, next) => prev.isActive !== next.isActive}
          >
            {(form: FormInstance) => {
              const isActive = form.getFieldValue("isActive");
              if (!isActive && !id) return <></>;

              return (
                <Form.Item
                  label={id ? t("reset-password") : t("password")}
                  className="custom-input-password"
                >
                  <PasswordInput isEdit={!!id} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const PasswordInput = ({ isEdit }: { isEdit: boolean }) => {
  const pLabel = isEdit ? "new-password" : "password";
  const pConfirmLabel = isEdit ? "confirm-new-password" : "confirm-password";
  const pKey = isEdit ? "newPassword" : "password";
  const { t } = useTranslation();
  return (
    <Row
      gutter={[24, 24]}
      style={{
        border: "1px solid #e4e9f2",
        marginLeft: "0px",
        marginRight: "0px",
      }}
      className="pb-4 px-3"
    >
      <Col span={12}>
        <Form.Item
          label={t(pLabel)}
          rules={[
            { required: !isEdit },
            {
              pattern: rules.password.rule,
              message: t(rules.password.tooltip),
            },
          ]}
          name={pKey}
        >
          <Input.Password placeholder={t(pLabel)} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={t(pConfirmLabel)}
          rules={[
            { required: !isEdit },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (
                  !value ||
                  getFieldValue(isEdit ? "newPassword" : "password") === value
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(t("passwords-do-not-match"));
              },
            }),
          ]}
          name="confirmNewPassword"
        >
          <Input.Password placeholder={t(pConfirmLabel)} />
        </Form.Item>
      </Col>
      {isEdit
        ? [
            <Col span={12} key={1}>
              <Form.Item
                shouldUpdate={(prev, next) => prev.password !== next.password}
              >
                {(form: FormInstance) => {
                  const hasPwd = form.getFieldValue("password");
                  return (
                    <Form.Item
                      label={t("old-password")}
                      rules={[{ required: !!hasPwd }]}
                      name="oldPassword"
                    >
                      <Input.Password placeholder={t("old-password")} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>,
            <Col span={12} key={2}></Col>,
          ]
        : null}
    </Row>
  );
};

export default UserResidentEditPage;
